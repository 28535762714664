export function getContentByErrorCode(errorId: string | null) {
  switch (errorId) {
    case '626':
    case '627':
    case '628':
    case '629':
      return {
        title: 'Sorry, we’re unable to provide a quote for you',
        text: `Unfortunately, we can’t explain why. It can happen for different reasons.\nIf you need help, call us on 0333 103 7791. \n\nWe're here:\nMonday to Friday 08:30-18:00\nSaturday: 09:00-13:00\nBank Holidays: 08:00-16:00`,
      }
    case 'purchase_failed':
      return {
        title: "Let's try that again",
        text: `Unfortunately we could not complete this transaction and no payment has been taken.\n\nYou can try again below or give us a call on 0333 103 7791 where we will be happy to continue your quote for you.`,
      }
    default:
      return {
        title: 'We’ve encountered an issue',
        text: `Unfortunately we’re experiencing some technical problems, but we are still here to help you.\n\nIf you contact our Customer Service team on 0333 103 7791 they will be able to continue where you left off.\n\nWe’re here\n\nMonday to Friday: 08:30-18:00\n\nSaturday: 09:00-13:00\n\nBank Holidays: 08:00-16:00`,
      }
  }
}
