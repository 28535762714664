import React from 'react'

const SagePayFailure = () => {
  const cancelled = window.location.search.includes('cancelled')

  React.useEffect(() => {
    setTimeout(() => {
      if (window.top) {
        if (cancelled) {
          window.top.postMessage(
            JSON.stringify({
              error: false,
              message: 'cancelled',
            }),
            '*',
          )
        } else {
          window.top.postMessage(
            JSON.stringify({
              error: false,
              message: 'failure',
            }),
            '*',
          )
        }
      }
    }, 5000)
  }, [])

  return (
    <div>
      <div style={{ width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {<h1>{cancelled ? 'Payment cancelled' : 'Lets try that again'}</h1>}</div>
        {!cancelled ? 
          <>
          <div style={{ textAlign: 'center'}}>
          <p>Unfortunately we could not complete this transaction and no payment has been taken.</p>
          <p>You can try again below or give us a call on 0333 103 7791 where we will be happy to continue your quote for you.</p>  
          </div>        
          </>
        : null}
      </div>
  )
}

export default SagePayFailure
