import React from 'react'
import { Formik, FormikProps } from 'formik'
import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import moment from 'moment'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import DateAndTimePicker from 'components/ui/DateAndTimePicker'
import AboutYouSection from 'components/driver/AboutYouSection'
import NewDriverSection from 'components/driver/NewDriverSection'
import ConvictionsAndClaimsSection from 'components/driver/ConvictionsAndClaimsSection'

import { getContentById } from 'utils'
import { isEmpty, stringArrayToOptions, formToClaims, formToConvictions } from 'guidewire/formatTools'
import { guidewireRequestTools } from 'guidewire/guidewireRequestTools'

import validationSchema, { initialValues } from './validationSchema'

// TODO: Replace mock content with apis
import content from './addDriverContent.json'
import convictionCodes from './convictionCodes.json'
import { SelectedPolicy } from 'types/policy'

const useStyles = makeStyles(theme => ({}))

export interface Props {
  policyId: string
  addedDrivers: any[]
  startDate: string
  current: SelectedPolicy
  jobId: string
  continueAddDriverAction: (values: any) => void
  cancelMTAChangesAction: VoidFunction
}

const MTAAddDriver = ({
  policyId,
  addedDrivers,
  startDate,
  current,
  jobId,
  continueAddDriverAction,
  cancelMTAChangesAction,
}: Props) => {
  const clesses = useStyles()

  const formRef = React.useRef<FormikProps<any>>(null)
  const [mulitChangeSubmit, setMultiChangeSubmit] = React.useState(false)

  // TODO: At the moment this function is WIP and setup to test a single MTA to add a driver
  // This also applies to the Saga (which is incomplete)
  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      const startDateValue = moment(values.startDate).utc(true)
      const startTimeValue = moment(values.startTime).utc(true)

      startDateValue.set('hours', startTimeValue.hours())
      startDateValue.set('minutes', startTimeValue.minutes())

      // Convert form values to GW
      const requestObject = guidewireRequestTools().additionalDriver(values, true)

      // guidewireRequestTools().additionalDriver(values) is taken from TYA QB journey and adds a key called tempID when adding a new driver
      // it is not required for MTA's

      const newAddedDrivers = addedDrivers ? addedDrivers : []
      newAddedDrivers.push(requestObject)

      continueAddDriverAction({
        startDate: startDate.length > 0 ? startDate : startDateValue.toISOString(),
        addedDrivers: newAddedDrivers,
        furtherChanges: mulitChangeSubmit,
        current,
        jobId,
      })
    }
  }

  const editInitialValues = initialValues
  editInitialValues.startDate = startDate
  editInitialValues.startTime = startDate

  return (
    <>
      {isEmpty(content) === false && (
        <Formik
          innerRef={formRef}
          initialValues={startDate.length > 0 ? editInitialValues : initialValues}
          onSubmit={async values => handleSubmit(values)}
          validationSchema={validationSchema()}
          validateOnBlur={false}
          enableReinitialize
          isInitialValid={false}
        >
          {props => (
            <Layout>
              <Box pt={5} pb={5}>
                <DateAndTimePicker disabled={startDate.length > 0} formRef={formRef} minimumDate={current.effective} />
              </Box>

              <Section>
                <AboutYouSection
                  additionalDriversPage
                  values={props.values}
                  content={getContentById(content, 'additionalDriverAboutYouSection')}
                  formRef={formRef}
                />

                <Box pt={4} />

                <NewDriverSection
                  content={getContentById(content, 'additionalDriverDrivingExperienceSection')}
                  values={props.values}
                />

                <Box pt={4} />

                <ConvictionsAndClaimsSection
                  content={getContentById(content, 'convictionsAndClaimsQuestions')}
                  values={props.values}
                  convictionCodes={stringArrayToOptions({
                    values: convictionCodes.sort().filter(element => element),
                  })}
                  convictionsContent={getContentById(content, 'addConvictionSection')}
                  claimsContent={getContentById(content, 'addClaimSection')}
                  confirmConvictions={e => props.setFieldValue('pmMotorConvictions', formToConvictions(e))}
                  confirmClaims={e => props.setFieldValue('pmClaims', formToClaims(e))}
                />
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <Button variant="outlined" color="secondary" onClick={cancelMTAChangesAction}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          setMultiChangeSubmit(true)
                          const submit = async () => await props.submitForm()
                          submit()
                        }}
                      >
                        Make further changes
                      </Button>
                    </Grid>
                    {startDate.length == 0 && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ margin: 'auto' }}
                          onClick={() => {
                            setMultiChangeSubmit(false)
                            const submit = async () => await props.submitForm()
                            submit()
                          }}
                        >
                          {'Add Driver'}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Section>
            </Layout>
          )}
        </Formik>
      )}
    </>
  )
}

export default MTAAddDriver
