import { Box, FormControl, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core'

import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(theme => ({
  field: {
    padding: '8px 20px 8px 4px',
    height: '50px',
    width: 'fit-content',
    borderRadius: '4px',
    marginLeft: 0,
  },
  fieldBorder: {
    border: 'solid 1px #d8d8d8',
  },
  fieldBorderError: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
}))

export interface MyRenewalPreferencesProps {
  policyId: string
  autoRenew: boolean
  updateRenewalPreferenceAction: (action: any) => void
  refreshPolicy: () => void
  isRenewalWithin30Days: boolean
}

const MyRenewalPreferences = ({
  policyId,
  autoRenew,
  updateRenewalPreferenceAction,
  refreshPolicy,
  isRenewalWithin30Days,
}: MyRenewalPreferencesProps) => {
  const classes = useStyles()

  const handleOnClick = (option: string) => {
    updateRenewalPreferenceAction({
      policyId,
      value: option === 'optIn' ? true : false,
      refreshPolicy: () => refreshPolicy(),
    })
  }

  return (
    <div>
      <ReactMarkdown>{`If you’ve opted into automatic renewal, you don't need to do anything further - We'll renew your policy automatically using the payment details you've shared with us.

        \n\n If we don’t hold card details for you, or your saved card has expired, we’ll contact you by email and SMS to ask you to contact us to make a payment.

        \n\n If you pay by monthly direct debits, this will be applied to your credit agreement instead of charging your card.

        \n\n If you want to opt out of automatic renewal, you can do this up to 30 days before your policy is due to end. You can do this in Your Portal, on Live Chat or call us on 0333 103 7791.

        \n\n We’ll send your renewal offer 21 days before your policy ends. If we don’t hear from you, we’ll renew your policy and your renewal payment will be taken from your account 3 days before your policy expires. If you are not the cardholder, you must check they agree to make the payment and let them know what the amount is.

        \n\n If you’ve opted for manual renewal and want to accept your renewal offer, you’ll need to do this in Your Portal, on Live Chat, or call us on 0333 103 7791 before your policy ends. If you don’t have a motor insurance policy in force and you use your car on public roads, you run the risk of prosecution.

        \n\n You can change your preferences by updating your choice below or contacting us on Live Chat.

        \n\n Save time and stay insured with  Toyota Insurance Services.`}</ReactMarkdown>

      <Box pt={4}>
        <FormControl error={false}>
          <RadioGroup
            row
            value={autoRenew === true ? 'optIn' : 'optOut'}
            onChange={(event: any) => handleOnClick(event.target.value)}
          >
            <FormControlLabel
              className={`${classes.field} ${classes.fieldBorder}`}
              value="optIn"
              control={<Radio />}
              label="Opt in"
              disabled={isRenewalWithin30Days}
            />
            <FormControlLabel
              className={`${classes.field} ${classes.fieldBorder}`}
              value="optOut"
              control={<Radio />}
              label="Opt out"
              disabled={isRenewalWithin30Days}
            />
          </RadioGroup>
          {/* TODO: Needs an error in case of failed api call. none specifed at this time */}
          {/* {error && <FormHelperText>{'Error text'}</FormHelperText>} */}
        </FormControl>
      </Box>
    </div>
  )
}

export default MyRenewalPreferences
