import React from 'react'
import { Formik, FormikProps } from 'formik'
import { Box, Button, Grid, makeStyles } from '@material-ui/core'

import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import AboutYouSection from 'components/driver/AboutYouSection'
import NewDriverSection from 'components/driver/NewDriverSection'
import ConvictionsAndClaimsSection from 'components/driver/ConvictionsAndClaimsSection'

import { getContentById } from 'utils'
import { isEmpty, stringArrayToOptions, driverValues, formToClaims, formToConvictions } from 'guidewire/formatTools'

import validationSchema, { initialValues } from './validationSchema'

// TODO: Replace mock content with apis
import content from './addDriverContent.json'
import convictionCodes from './convictionCodes.json'
import { guidewireRequestTools } from 'guidewire/guidewireRequestTools'

export interface Props {
  addedDrivers: any[]
  additionalDrivers: any[]
  continueAddDriverAction: (values: any) => void
  addRenewalDriverAction: (values: any) => void
}

const RenewalAddDriver = ({
  addedDrivers,
  additionalDrivers,
  continueAddDriverAction,
  addRenewalDriverAction,
}: Props) => {
  const formRef = React.useRef<FormikProps<any>>(null)

  const [driver, setDriver] = React.useState({})
  const [driverCheck, setDriverCheck] = React.useState(false)

  const handleSubmit = (values: any) => {
    if (formRef?.current?.isValid) {
      if (isEmpty(driver)) {
        const requestObject = guidewireRequestTools().additionalDriver(values, true)

        const newAddedDrivers = addedDrivers ? addedDrivers : []
        newAddedDrivers.push(requestObject)

        addRenewalDriverAction({ addedDrivers: newAddedDrivers, additionalDrivers: additionalDrivers })
      } else {
        const index = parseInt(new URLSearchParams(window.location.search).get('edit_id') || '0')
        const newDriver = new URLSearchParams(window.location.search).get('new')

        if (newDriver === 'true') {
          //edit new driver
          const requestObject = guidewireRequestTools().additionalDriver(values, true)
          addedDrivers[index] = requestObject

          addRenewalDriverAction({ addedDrivers: addedDrivers, additionalDrivers: additionalDrivers })
        } else {
          //edit edisitng dirver
          const requestObject = guidewireRequestTools().additionalDriver(values, false)
          requestObject.updateOrRemove = 'update'
          additionalDrivers[index] = requestObject

          addRenewalDriverAction({ addedDrivers: addedDrivers, additionalDrivers: additionalDrivers })
        }
      }
    }
  }

  React.useEffect(() => {
    if (addedDrivers || additionalDrivers) {
      const index = parseInt(new URLSearchParams(window.location.search).get('edit_id') || '0')
      const newDriver = new URLSearchParams(window.location.search).get('new')

      let editDriver
      if (newDriver) {
        if (newDriver === 'true') {
          editDriver = addedDrivers[index]
        } else {
          editDriver = additionalDrivers[index]
        }
      }

      // Edit journey, already converted
      if (editDriver) {
        setDriver(driverValues(editDriver))
      }

      // There is a confusing error with the dateofbirth field without this check
      // editing the first additional driver, all values load correctly
      // editing a second time or second driver, the dateofbirth value is missing
      setDriverCheck(true)
    } else {
      setDriverCheck(true)
    }
  }, [driverCheck])

  return (
    <>
      {isEmpty(content) === false && driverCheck && (
        <Formik
          innerRef={formRef}
          initialValues={isEmpty(driver) ? initialValues : driver}
          onSubmit={async values => handleSubmit(values)}
          validationSchema={validationSchema()}
          validateOnBlur={false}
          enableReinitialize
          isInitialValid={false}
        >
          {props => (
            <Layout>
              <Section>
                <AboutYouSection
                  additionalDriversPage
                  values={props.values}
                  content={getContentById(content, 'additionalDriverAboutYouSection')}
                />
                <Box pt={4} />
                <NewDriverSection
                  content={getContentById(content, 'additionalDriverDrivingExperienceSection')}
                  values={props.values}
                />
                <Box pt={4} />
                <ConvictionsAndClaimsSection
                  content={getContentById(content, 'convictionsAndClaimsQuestions')}
                  values={props.values}
                  convictionCodes={stringArrayToOptions({
                    values: convictionCodes.sort().filter(element => element),
                  })}
                  convictionsContent={getContentById(content, 'addConvictionSection')}
                  claimsContent={getContentById(content, 'addClaimSection')}
                  confirmConvictions={e => props.setFieldValue('pmMotorConvictions', formToConvictions(e))}
                  confirmClaims={e => props.setFieldValue('pmClaims', formToClaims(e))}
                />
                <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <Button variant="outlined" color="secondary" onClick={() => history.back()}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          const submit = async () => await props.submitForm()
                          submit()
                        }}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Section>
            </Layout>
          )}
        </Formik>
      )}
    </>
  )
}

export default RenewalAddDriver
