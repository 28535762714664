import { Button, Typography } from '@material-ui/core'
import Layout from 'components/ui/Layout'
import React from 'react'
import Iframe from 'react-iframe'
import { history } from 'utils'
import { makeStyles } from '@material-ui/core/styles'

export interface Props {
  paymentURL: string
  jobId: string
  amountToDisplay: string
  displayTotalBanner: boolean
  bindPaymentAction?: (values: any) => void
  renewalBindPaymentAction?: () => void
  onCancel?: () => void
}

const useStyles = makeStyles(theme => ({
  totalAmountContainer: {
    marginTop: 40, 
    backgroundColor: '#484950'
  },
  totalAmountContainerHidden: {
    display: 'none'
  },
  totalAmountOutput: {
    fontSize: 25, 
    textAlign: 'center', 
    color: '#fff', 
    padding: 20
  },
}))

const SagePayIframe = ({ paymentURL, jobId, amountToDisplay, displayTotalBanner, bindPaymentAction, renewalBindPaymentAction, onCancel }: Props) => {
  // Simple check to make sure the api to bind only fires once
  const [count, setCount] = React.useState(0)
  const [url, setUrl] = React.useState(paymentURL)
  const [displayTotal, setAmount] = React.useState(amountToDisplay)
  const [displayTotalContainer, setDisplayTotalBanner] = React.useState(displayTotalBanner)
  const classes = useStyles()

  React.useEffect(() => {
    //add message listener
    window.addEventListener(
      'message',
      event => {
        try {
          if (event.data === 'scrollToTop') {
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }, 5000)
          }
          const data = JSON.parse(event.data)
          if (data.message === 'success') {
            if (count < 1 && process.env.NODE_ENV !== 'development') {
              // This is used by mtas
              if (bindPaymentAction) bindPaymentAction({ reuseExistingCard: false, jobId: jobId })
              // this is used to trigger renewal annual payments
              if (renewalBindPaymentAction) renewalBindPaymentAction()
              setCount(1)
            } else {
              // This is used by mtas
              if (bindPaymentAction) bindPaymentAction({ reuseExistingCard: false, jobId: jobId })
              // this is used to trigger renewal annual payments
              if (renewalBindPaymentAction) renewalBindPaymentAction()
            }
          }

          if (data.message === 'failure') {
            console.log('failed payment')
            history.push('/declined?error=purchase_failed')
          }

          // This is done in anticipation of changing how the cancelled / failed messages are handled
          // For now it will just redirect to failed "please call us" screen
          if (data.message === 'cancelled') {
            console.log('In cancelled')

            if (onCancel !== undefined) {
              onCancel()
            } else {
              history.push('/declined?error=purchase_failed')
            }
          }
        } catch (error) {
          //
        }
      },
      false,
    )
  }, [])

  return (
    <Layout>
      <div className={displayTotalContainer ? classes.totalAmountContainer : classes.totalAmountContainerHidden}>
        <p className={classes.totalAmountOutput}>Your total payment is £{displayTotal}</p>
      </div>
      <div style={{ height: '1050px', marginTop: 20 }}>
        <Iframe frameBorder={0} width={'100%'} height={'100%'} url={url} />
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div style={{ padding: 16, border: '1px solid red' }}>
          <Typography>These buttons only appear in development, working locally</Typography>
          <Typography>
            Instructions: Fill out the SagePay form as normal. <br />
            <br />
            After the password screen the SagePay redirects the Iframe to PaymentSuccess / PaymentFailure components of
            this app. When working locally you will see a grey background because GW does not have the routes configured
            to redirect to localhost and redirects to one of the deployment domains (dev, sfedev, qa, prod etc.). <br />
            <br />
            You can see in the network tab whether SagePay redirected to success or failure. <br />
            <br />
            At this point you click either the success or failure buttons below to trigger the final payment steps
          </Typography>
          <div style={{ display: 'flex', gap: 16, marginTop: 16 }}>
            <Button variant="outlined" color="secondary" onClick={() => setUrl('/payment-failure')}>
              Failure
            </Button>
            <Button variant="outlined" color="primary" onClick={() => setUrl('/payment-success')}>
              Success
            </Button>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default SagePayIframe
