import { accountHolderToDisplay } from 'guidewire/format/accountHolderToDisplay'
import { capitalizeString } from 'guidewire/format/capitalizeString'
import { AccountHolder } from '../types/accountHolder'

export const accountHolderUtils = (accountHolder: AccountHolder) => {
  const { displayName } = accountHolder

  const yourTitleOptions = [
    {
      "label": "Mr.",
      "value": "mr"
    },
    {
      "label": "Mrs.",
      "value": "mrs"
    },
    {
      "label": "Miss",
      "value": "miss"
    },
    {
      "label": "Ms.",
      "value": "ms"
    },
    {
      "label": "Dr.",
      "value": "dr"
    },
    {
      "label": "Master.",
      "value": "master_itb"
    },
    {
      "label": "Mx.",
      "value": "mx_itb"
    }
  ]

  const getTitleLabel = (titleValue: string): string => {
    const title = yourTitleOptions.find(option => option.value === titleValue);
    return title ? title.label : titleValue;
  };

  const details = () => accountHolder

  const display = () => {
    return accountHolderToDisplay(accountHolder)
  }

  const getDisplayName = (withTitle?: 'withTitle') => {
    const title = getTitleLabel(accountHolder.title)
    if (withTitle) return capitalizeString(`${title} ${displayName}`)
    return capitalizeString(displayName)
  }

  return {
    details,
    display,
    getDisplayName,
  }
}
