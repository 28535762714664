import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { getContentByErrorCode } from 'utils/decline'
import ReactMarkdown from 'react-markdown'

interface Props {
  errorCode: string
}

interface ErrorContent {
  title: string
  text: string
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 30,
    fontWeight: 'normal',
    lineHeight: 1.42,
  },
  text: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.43,
  },
}))

const DeclineMessage = ({ errorCode }: Props) => {
  const [errorContent, setErrorContent] = React.useState<ErrorContent>()
  const classes = useStyles()

  React.useEffect(() => {
    setErrorContent(getContentByErrorCode(errorCode))
  }, [])

  return (
    <>
      {errorContent && (
        <Box pt={4} pb={4}>
          <h1 className={classes.title}>{errorContent.title}</h1>
          <ReactMarkdown className={classes.text}>{errorContent.text}</ReactMarkdown>
        </Box>
      )}
    </>
  )
}

export default DeclineMessage
