import { connect } from 'react-redux'
import { bindPaymentAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'

import SagePayIframe from './SagePayIframe'

const mapStateToProps = (state: IReduxState) => {
  let displayTotalBannerValue = false
  let totalToDisplay = ''
  if (state.mta?.mtaResponse !== undefined) {
    if (state.mta.mtaResponse?.status === 'Quoted') {
      displayTotalBannerValue = true
      if (typeof state.mta.mtaResponse?.totalCost?.amount === 'number') {
        displayTotalBannerValue = true
        totalToDisplay = String(state.mta.mtaResponse?.totalCost?.amount)
      }
    }
  }
  return {
    paymentURL: state.mta.paymentURL,
    jobId: state.mta.jobId,
    amountToDisplay: totalToDisplay,
    displayTotalBanner: displayTotalBannerValue
  };
}

const mapDispatchToProps = {
  bindPaymentAction: bindPaymentAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(SagePayIframe)
