import { useFormik } from 'formik'

import { Box, Button, Grid } from '@material-ui/core'

import BillingDetails from 'components/payment/BillingDetails'
import RegisteredToYou from 'components/payment/RegisteredToYou'
import StoreCard from 'components/payment/StoreCard'
import Layout from 'components/ui/Layout'
import Section from 'components/ui/Section'
import { RadioYesNoProps } from 'components/formUI/RadioYesNo/RadioYesNo'

import { renewalQuote } from 'guidewire/policy/quote/renewalQuote'
import { CardPaymentForm, CardPaymentRequest } from 'guidewire/policy/types/cardPaymentRequest'

import paymentAnnualValidation from 'guidewire/policy/formValidation/paymentAnnual'
import SagePayIframe from 'pages/SagePayIframe/SagePayIframe'
import { LabelValuePair } from 'types'
import { RenewalQuote } from 'guidewire/policy/types/renewalQuote'
import { history } from 'utils'
import { makeStyles } from '@material-ui/core/styles'

export interface Props {
  quote: RenewalQuote
  policyNumber: LabelValuePair
  renewalAnnualGetSagepayurlAction: ({
    requestObject,
    quoteID,
  }: {
    requestObject: CardPaymentRequest
    quoteID: string
  }) => void
  renewalBindPaymentAction: ({
    requestObject,
    quoteID,
    redirectUrl,
  }: {
    requestObject: { selectedQuote: string; selectedPaymentPlan: string; autoRenew: boolean }
    quoteID: string
    redirectUrl: string
  }) => void
}

const useStyles = makeStyles(theme => ({
  totalAmountContainer: {
    paddingTop: '16px',
    backgroundColor: '#282830',
    textAlign: 'center',
    display: 'block',
  },
  totalAmountContainerHidden: {
    display: 'none',
  },
  totalAmountOutput: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: 10,
    marginTop: 0 
  },
}))

const RenewalAnnualPayment = ({
  quote,
  policyNumber,
  renewalAnnualGetSagepayurlAction,
  renewalBindPaymentAction,
}: Props) => {
  // IMPORTANT: This is entirely wrapped in an if statement because of how the quote object updates once a payment is successfully made
  // Once this happens bindData object loses many of its values, values this page expects so it crashes. this happens because quote updates before the redirect has a chance to trigger
  // This bindData object is not used again after this page so it makes no sense to change the BindData type, then have to specify values as their correct type and not undefined on this page
  if (quote.bindData.selectedPaymentPlan) {
    const gw = renewalQuote(quote)
    const total = gw.getBindData.getSelectedPaymentPlan().total;
    const amountToDisplay = typeof total === 'object' && 'amount' in total && typeof total.amount === 'number' ? String(total.amount) : '0.00'
    const displayTotalContainer  = amountToDisplay !== '0.00' ? true: false
    const classes = useStyles()
    // Will be undefined on first load
    // When the form details are submitted, if api call is success, quote is updated with a SagepayURL value
    // This then hides the form and shows the sagepayIframe
    const sagePayURL = gw.getBindData.getSagePayURL()
    // Form intitial values
    const billingAddress = gw.getBindData.getBillingAddress()
    const { validationSchema, initialValues } = paymentAnnualValidation

    const legelText = `__Important: Do you want us to store your card details for any future payments and to allow us to automatically renew your policy when your policy ends?__ \n\n By selecting yes, you're setting up a Continuous Payment Authority. This means we'll save your card to use for any policy changes you make until your policy ends and automatically renew your policy, making sure you won't be left without insurance. \n\n Payment for your renewal will be taken 3 days before your policy is due to expire. If this attempt is unsuccessful, we'll attempt to collect the payment 3 more times until your renewal date passes. No payment attempts will be made after your policy expiry date and you will be left without insurance if we don't receive payment. \n\n You can cancel this any time free of charge during your policy by visiting Your Portal and selecting 'My Renewal Preferences', or by contacting us on Live Chat or calling on 0333 103 7791. If you want to opt out after we've issued your renewal notice, you must do this 3 days prior to your policy expiry date or we will take payment and your policy will automatically renew. \n\n If this isn't your card, it's your responsibility to get the card holder's permission to store and use this card for future payments. \n\n By selecting No, your card details won't be saved for any future payments and your policy will not automatically renew. If you don't have a motor insurance policy in force and you use your car on public roads, you run the risk of prosecution. If you want to accept our renewal offer, you'll need to do this in Your Portal or call us on 0333 103 7791 before your policy expires. `

    const registeredToYoutext = `If you are not the cardholder it is your responsibility to ensure that you have the cardholder's authority to make this payment and for this card to be used for automatic renewal. You must also advise the cardholder of any changes to the premium.`

    const handleSubmit = (values: CardPaymentForm) => {
      const paymentRequest = gw.createAnnualPaymentRequest(values)

      renewalAnnualGetSagepayurlAction({
        requestObject: paymentRequest,
        quoteID: policyNumber.value,
      })
    }

    const formik = useFormik({
      // Note: this merges the default values (which are all empty string '') with the billingAddress object
      // This prefills the main fields, the 4 additional fields if the card is not registered, remain as default values
      // Using this method is quick but initial values contain some values that are unneeded (addressLine3, publicId etc.)
      // this has no effect on the functionality of the form but its worth filtering at some point
      initialValues: { ...initialValues, ...billingAddress },
      validationSchema,
      onSubmit: values => handleSubmit(values),
    })

    return (
      <>
        {sagePayURL === undefined && (
          <>
            <Layout background="white">
              <Section>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <StoreCard
                      title="Legal and payment"
                      text={legelText}
                      radioProps={{ name: 'isAutomaticRenewal', pb: 0, formik } as RadioYesNoProps}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RegisteredToYou
                      title="Card details"
                      text={registeredToYoutext}
                      radioProps={
                        {
                          name: 'isCardRegisteredToYou',
                          label: 'Is this card registered to you?',
                          pb: 2,
                          formik,
                        } as RadioYesNoProps
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <BillingDetails
                      cardNotRegisteredFields={formik.values.isCardRegisteredToYou === 'no'}
                      title="Billing details"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Section>
            </Layout>

            <Layout background="#eff0f0">
              <Box pt={8} pr={2.5} pl={2.5} pb={4}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <Button variant="outlined" color="secondary" onClick={() => history.push('/renewal-documents')}>
                      Go back
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={formik.submitForm}>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Layout>
          </>
        )}

        {sagePayURL !== undefined && (
          <>
            <div className={displayTotalContainer ? classes.totalAmountContainer : classes.totalAmountContainerHidden}>
              <p className={classes.totalAmountOutput}>Your renewal amount is £{amountToDisplay}</p>
            </div>
            <SagePayIframe
              // This is done in anticipation of changing how the cancelled / failed methods are handled
              // onCancel={() => {
              //   console.log('1. Remove Sagepay url from the quote')
              //   console.log('2. trigger local state to show / hide Iframe')
              // }}
              paymentURL={sagePayURL}
              jobId=""
              amountToDisplay=""
              displayTotalBanner={false}
              renewalBindPaymentAction={() =>
                renewalBindPaymentAction({
                  redirectUrl: '/renewal-payment-complete',
                  quoteID: policyNumber.value,
                  requestObject: {
                    selectedQuote: gw.getBindData.getSelectedPaymentPlan().periodID,
                    selectedPaymentPlan: gw.getBindData.getSelectedBillingId(),
                    autoRenew: gw.getBindData.details().autoRenew,
                  },
                })
              }
            />
          </>
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default RenewalAnnualPayment
